import React, { useState } from 'react';
import './Timer.css';

const Timer = () => {
  const [time] = useState({
    days: '12',
    hours: '24',
    minutes: '59',
    seconds: '12',
  });

  const renderDoubleDigits = (timeStr) => {
    return timeStr.split('').map((digit, index) => (
      <div key={index} className="timer-double-square">
        <div className="timer-value">{digit}</div>
      </div>
    ));
  };

  return (
    <div className="timer-container">
      <div className="timer">
        <div className="timer-section">
          <div className="timer-double">
            {renderDoubleDigits(time.days)}
          </div>
          <div className="timer-label">Days</div>
        </div>
        <div className="timer-section">
          <div className="timer-double">
            {renderDoubleDigits(time.hours)}
          </div>
          <div className="timer-label">Hours</div>
        </div>
        <div className="timer-section">
          <div className="timer-double">
            {renderDoubleDigits(time.minutes)}
          </div>
          <div className="timer-label">Minutes</div>
        </div>
        <div className="timer-section">
          <div className="timer-double">
            {renderDoubleDigits(time.seconds)}
          </div>
          <div className="timer-label">Seconds</div>
        </div>
      </div>
    </div>
  );
};

export default Timer;

import React from 'react';
import './About.css';

function About() {
  return (
    <div className='about-body'>
      <div className='translucent-box'>
      <h1 className='about-section'>About Us</h1>
      <p className='about-para'>Lorem ipsum dolor sit amet consectetur. Ac enim lorem tortor sem ornare ut sed proin. Ac nisl risus amet non non commodo sed id aliquam. Sit mattis turpis id egestas lorem neque. Commodo nec nibh fames mattis eget vulputate. Pulvinar tempus purus odio et quis et sagittis sed ut. Donec pellentesque et aliquet etiam justo lacus nisl. Lorem est in etiam ornare cursus aenean non dictum faucibus. Velit massa mauris ullamcorper nulla hendrerit pulvinar turpis dui ultricies. Convallis velit interdum laoreet at sagittis tincidunt lectus. Risus egestas dolor habitant senectus tortor donec tempor sit viverra.</p>
      </div>
    </div>
  );
}

export default About;

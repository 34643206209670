import React from 'react';
import './Footer.css';
import insta from '../assets/insta.svg';
import linkedin from '../assets/linkedin.svg';
import wa from '../assets/wa.svg';
import spotify from '../assets/spotify.svg'

function Footer() {
  return (
    <footer className='footer-body'>
      <h1 className='paradox'>PARADOX</h1>
      <div >
        <center className="click">
        <a href="./"><img src={insta} alt='instagram icon'></img></a>
        <a href="./"><img src={linkedin} alt='linkedin icon'></img></a>
        <a href="./"><img src={wa} alt='whatsapp icon'></img></a>
        <a href="./"><img src={spotify} alt='spotify icon'></img></a>
        </center>
      </div>
      <p className='rights'>© IEEE Computer Society VIT Chennai 2024 - All Rights Reserved.</p>
    </footer>
  );
}

export default Footer;

import React, { useState } from 'react';
import './Faq.css';
import arrow from '../assets/arrow.svg';

function Faq() {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleQuestion = index => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqs = [
    { question: "QUESTION 1 GOES HERE", answer: "Content for question 1 goes here..." },
    { question: "QUESTION 2 GOES HERE", answer: "Content for question 2 goes here..." },
    { question: "QUESTION 3 GOES HERE", answer: "Content for question 3 goes here..." },
    { question: "QUESTION 4 GOES HERE", answer: "Content for question 4 goes here..." },
    { question: "QUESTION 5 GOES HERE", answer: "Content for question 5 goes here..." },
    { question: "QUESTION 6 GOES HERE", answer: "Content for question 6 goes here..." },
    { question: "QUESTION 7 GOES HERE", answer: "Content for question 7 goes here..." },
  ];

  return (
    <div className='faq-body'>
      <h1 className='heading-faq'>FAQ</h1>
      <div className='boxes'>
        {faqs.map((faq, index) => (
          <div 
            key={index} 
            className={`question-boxes ${activeIndex === index ? 'active' : ''}`} 
            onClick={() => toggleQuestion(index)}
          >
            <h4>{faq.question}</h4>
            <img className='downarrow' src={arrow} alt="arrow" />
            <div className={`expanded-content ${activeIndex === index ? 'open' : ''}`}>
              {faq.answer}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Faq;

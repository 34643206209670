import React from 'react';
import './Team.css';

const teamMembers = [
  { name: 'NAME', position: 'position', others: 'others', imageUrl: require('../assets/id.png'), profileUrl: require('../assets/pfp.png') },
  { name: 'NAME', position: 'position', others: 'others', imageUrl: require('../assets/id.png'), profileUrl: require('../assets/pfp.png') },
  { name: 'NAME', position: 'position', others: 'others', imageUrl: require('../assets/id.png'), profileUrl: require('../assets/pfp.png') },
  { name: 'NAME', position: 'position', others: 'others', imageUrl: require('../assets/id.png'), profileUrl: require('../assets/pfp.png') },
  { name: 'NAME', position: 'position', others: 'others', imageUrl: require('../assets/id.png'), profileUrl: require('../assets/pfp.png') },
  { name: 'NAME', position: 'position', others: 'others', imageUrl: require('../assets/id.png'), profileUrl: require('../assets/pfp.png') },
  { name: 'NAME', position: 'position', others: 'others', imageUrl: require('../assets/id.png'), profileUrl: require('../assets/pfp.png') },
  { name: 'NAME', position: 'position', others: 'others', imageUrl: require('../assets/id.png'), profileUrl: require('../assets/pfp.png') },
  { name: 'NAME', position: 'position', others: 'others', imageUrl: require('../assets/id.png'), profileUrl: require('../assets/pfp.png') },
  { name: 'NAME', position: 'position', others: 'others', imageUrl: require('../assets/id.png'), profileUrl: require('../assets/pfp.png') },
  { name: 'NAME', position: 'position', others: 'others', imageUrl: require('../assets/id.png'), profileUrl: require('../assets/pfp.png') },
  { name: 'NAME', position: 'position', others: 'others', imageUrl: require('../assets/id.png'), profileUrl: require('../assets/pfp.png') },
  { name: 'NAME', position: 'position', others: 'others', imageUrl: require('../assets/id.png'), profileUrl: require('../assets/pfp.png') },
];

function Team() {
  return (
    <div className="team-body">
      <div className="team-box">
        <h1 className="heading-team">Our Team</h1>
        <div className="team-grid">
          {teamMembers.map((member, index) => (
            <div 
              key={index} 
              className="team-card"
              style={{ backgroundImage: `url(${member.imageUrl})` }}
            >
              <img className="profile-image" src={member.profileUrl} alt={member.name} />
              <div className="member-info">
                <p className="member-name">{member.name}</p>
                <p className="member-position">{member.position}</p>
                <p className="member-detail">{member.others}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Team;

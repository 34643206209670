import React from 'react';
import './Home.css';
import google from '../assets/google.svg';
import discord from '../assets/discord.svg';
import Timer from '../components/Timer';

function Home() {
  return (
    <div className='home-body'>
      <div className='div'>
      <div className='box'>
        <div className='gif-paradox'></div>
        <div className="buttons">
          <center><button className='google-button'><img className='google-icon'src={google} alt='google icon'></img>Login with Google</button>
          <button className='discord-button'><img className='discord-icon' src={discord} alt='discord icon'></img> Join our Discord</button></center>
        </div>
      </div>
      </div>
      <Timer/>
    </div>
  );
}

export default Home;

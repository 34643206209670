import React from 'react';
import './App.css';

import Home from './pages/Home';
import About from './pages/About';
import Team from './pages/Team';
import Faq from './pages/Faq';
import Footer from './components/Footer';

function App() {
  return (
    <div>
      <main>
      <div className="scroll-container">
        <section className="section" id="home"><Home /></section>
        <section className="section" id="about"><About /></section>
        <section className="section" id="team"><Team /></section>
        <section className="section" id="faq"><Faq /></section>
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default App;
